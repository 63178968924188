import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { LocaleTag } from "types/I18n";
import {
  BatchAggregationInput,
  BatchAggregationResponse,
  DatenpunkteTitel,
} from "requests/aggregationRequests";
import {
  DatenpunkteZeit,
  getBatchAggregationGQL,
} from "requests/aggregationRequests";

export type ChartStateType = {
  zeit: DatenpunkteZeit[];
  titel: DatenpunkteTitel[];
  status: "idle" | "loading" | "failed";
};

export const getBatchAggregation = createAsyncThunk(
  "chart/getBatchAggregation",
  async (props: {
    batchAggregationInput: BatchAggregationInput;
    localeTag: LocaleTag;
  }) => {
    try {
      const data = await getBatchAggregationGQL(
        props.batchAggregationInput,
        props.localeTag
      );
      console.log("DATA:", data);

      return data;
    } catch (error) {
      console.log("ERROR:", error);
      throw error;
    }
  }
);

const initialState: ChartStateType = {
  zeit: [],
  titel: [],
  status: "idle",
};

export const chartSlice = createSlice({
  name: "chart",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBatchAggregation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBatchAggregation.rejected, (state, action) => {
        state.status = "failed";
        console.log("Error: getBatchAggregation", action);
      })
      .addCase(
        getBatchAggregation.fulfilled,
        (state, action: PayloadAction<BatchAggregationResponse>) => {
          state.zeit = action.payload.zeit.datenpunkte;
          state.titel = action.payload.titel.datenpunkt;
          state.status = "idle";
        }
      );
  },
});

export const selectChartState = (state: RootState) => state.chart;

export default chartSlice.reducer;
