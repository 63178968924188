import { t, Trans } from "@lingui/macro";
import {
  Button,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useCallback } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DetailseiteInfo from "components/DetailseiteInfo";
import { useHistory } from "react-router-dom";
import { MeldungView } from "types/MeldungView";
import {
  getMeldungsEngagement,
  getMeldungsInfo,
  getMeldungsWeitereInfos,
} from "lib/converter";
import { useAppDispatch, useAppSelector } from "redux/store";
import { selectMeldungenState } from "redux/meldungen";
import {
  resetNavigationIndex,
  selectMeldungNavigationState,
  setNavigationIndex,
} from "redux/meldungNavigation";
import Hashtags from "components/DetailseiteInfo/Hashtags";
import { getMeldungenUrl, getMeldungsDetailUrl } from "lib/urls";
import Medias from "components/Medias";

type Props = {
  meldung: MeldungView;
  meldungDetailUrl: string;
};

export default function MeldungsDetailPaper(props: Props) {
  const { meldung } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const meldungenState = useAppSelector(selectMeldungenState);
  const meldungNavigationState = useAppSelector(selectMeldungNavigationState);
  const classes = useStyle();

  const handleKeyPress = useCallback((event) => {
    if (event.key === "ArrowRight") {
      if (
        meldungNavigationState.currentIndex >=
        meldungenState.elements.length - 1
      ) {
        return;
      } else {
        forward();
      }
    } else if (event.key === "ArrowLeft") {
      if (meldungNavigationState.currentIndex !== 0) {
        backward();
      }
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    const currentIndex =
      meldungenState.count === 0
        ? 0
        : meldungenState.elements.findIndex(
            (x) => x.meldungDetailUrl === props.meldungDetailUrl
          );
    dispatch(setNavigationIndex(currentIndex));

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    props.meldungDetailUrl,
    meldungenState.count,
    dispatch,
    meldungenState.elements,
    handleKeyPress,
  ]);

  const forward = () => {
    const nextMeldung =
      meldungenState.elements[meldungNavigationState.currentIndex + 1];

    history.push(getMeldungsDetailUrl(nextMeldung.meldungDetailUrl));
  };

  const backward = () => {
    const previousMeldung =
      meldungenState.elements[meldungNavigationState.currentIndex - 1];
    history.push(getMeldungsDetailUrl(previousMeldung.meldungDetailUrl));
  };

  const zurueck = () => {
    dispatch(resetNavigationIndex);
    history.push(getMeldungenUrl());
  };

  return (
    <Paper elevation={1} className={classes.paper}>
      <div className={classes.paperHeader}>
        <Button className={classes.button} onClick={zurueck}>
          <ArrowBackIosIcon fontSize="small" />
          <Trans id="begriff.zurueck">Zurück</Trans>
        </Button>
        <div className={classes.navigatorContainer}>
          <IconButton
            onClick={backward}
            disabled={meldungNavigationState.currentIndex === 0}
          >
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>
          <Trans id="begriff.meldungVonPrefix">Meldung</Trans>
          {` ${meldungNavigationState.currentIndex + 1} `}{" "}
          <Trans id="begriff.meldungVon"> von </Trans>
          {` ${meldungenState.count === 0 ? 1 : meldungenState.count}`}
          <IconButton
            onClick={forward}
            disabled={
              meldungNavigationState.currentIndex >=
              meldungenState.elements.length - 1
            }
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </div>
        <div className={classes.actionsContainer}></div>
      </div>
      <div className={classes.wrapper}>
        <>
          <div className={classes.contentContainer}>
            <Typography variant="caption" display="block">
              {meldung.veroeffentlicht}
            </Typography>
            <Typography
              variant="h5"
              display="block"
              component={Link}
              className={classes.headline}
            >
              <Link
                target="_blank"
                href={meldung.direktUrl}
                underline="none"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Typography
                  display="block"
                  variant="caption"
                  dangerouslySetInnerHTML={{
                    __html: meldung.headlineHtml,
                  }}
                ></Typography>
              </Link>
            </Typography>
            {meldung.mediaAttachments?.length > 0 && (
              <Medias images={meldung.mediaAttachments} />
            )}
            <div className={classes.seperator}></div>
            <Typography
              className={classes.meldungHTML}
              display="block"
              variant="caption"
              dangerouslySetInnerHTML={{
                __html: meldung.meldungHtml,
              }}
            ></Typography>
          </div>
          <div>
            <DetailseiteInfo
              headline={t({
                id: "begriff.meldungsInformationen",
                message: "Meldungsinformationen",
              })}
              dict={getMeldungsInfo(meldung)}
            />
            <div className={classes.seperator}></div>
            <DetailseiteInfo
              headline={t({
                id: "begriff.weitereInformationen",
                message: "Weitere Informationen",
              })}
              dict={getMeldungsWeitereInfos(meldung)}
            />
            <div className={classes.seperator}></div>
            <DetailseiteInfo
              headline={t({
                id: "begriff.engagement",
                message: "Engagement",
              })}
              dict={getMeldungsEngagement(meldung)}
            />
            <div className={classes.seperator}></div>
            <Hashtags
              hashtags={meldung.tags}
              headline={t({
                id: "begriff.tags",
                message: "tags",
              })}
            />
          </div>
        </>
      </div>
    </Paper>
  );
}

const useStyle = makeStyles((theme) => ({
  paper: {
    minHeight: "90vh",
    borderRadius: 10,
    width: 1300,
  },
  paperHeader: {
    margin: 10,
    borderBottom: "1px solid lightGray",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    fontWeight: "bold",
    alignItems: "center",
  },
  navigatorContainer: {
    fontWeight: "bold",
    textAlign: "center",
    alignItems: "center",
  },
  actionsContainer: {
    minWidth: "10%",
  },
  seperator: {
    marginTop: 20,
  },
  contentContainer: {
    paddingRight: 10,
    borderRight: "1px solid lightGray",
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "65% 35%",
    gap: 20,
    padding: 20,
  },
  headline: {
    fontWeight: "bolder",
    "& .mark": theme.textHighlight,
  },
  meldungHTML: {
    "& .mark": theme.textHighlight,
  },
}));
