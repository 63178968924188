import { makeStyles } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "redux/store";
import { changeFilter } from "redux/filter";
import { selectContainerState } from "redux/container";
import Suche from "./Suche";

export default function ErweiterteSuchleiste() {
  const classes = useStyle();
  const dispatch = useAppDispatch();
  const container = useAppSelector(selectContainerState);

  const onReset = () => {
    dispatch(
      changeFilter({
        herkunftslaender: container.herkunftslaender.map((h) => h.bezeichner),
        medienarten: container.medienarten.map((m) => m.bezeichner),
        sprachen: container.sprachen.map((s) => s.bezeichner),
        suchFelder: [],
        suche: "",
      })
    );
  };
  return (
    <div className={classes.container}>
      <div className={classes.filternUnSuchenContainer}>
        <Suche />
      </div>
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    background: "white",
    margin: 0,
    padding: 10,
    borderRadius: 10,
    border: "1px solid rgb(228,230,235)",
    boxShadow: "rgba(9,32,77,0.12) 0px 3px 8px rgba(29,17,51,0.12) 0px 0px 2px",
    backgroundColor: "white",
  },
  filternUnSuchenContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  clearButton: {
    cursor: "pointer",
    padding: 5,
  },
  separator: {
    borderLeft: "1px solid rgb(240, 240, 240)",
    alignSelf: "stretch",
    margin: "5px 0.5rem",
    marginLeft: 10,
    marginRight: 10,
  },
});
