import { Trans } from "@lingui/macro";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { getExportListe, selectContainerState } from "redux/container";
import {
  deleteMeldung,
  getExportListeMeldungen,
  removeFromExportListe,
  selectExportListeState,
} from "redux/exportListe";
import { exportListeLeeren, toggleMeldung } from "redux/meldungen";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getMeldungenByIdsGQL } from "requests/meldungenRequests";
import { Auftrag } from "types/Auftrag";
import { MeldungView } from "types/MeldungView";
import AuftraegePopover from "./AuftragsPopover";
import AuftragsToolbar from "./AuftragsToolbar";
import ListenHeader from "./ListenHeader";
import ListenItem from "./ListenItem";

type ListenImportPanelProps = {
  auftraege: Auftrag[];
  letzterAuftrag: Auftrag;
  onCancel: () => void;
  onExport: (auftragsId: string, labels: string[]) => void;
};

export default function ListenImport(props: ListenImportPanelProps) {
  const classes = useStyles();

  const [page, setPage] = React.useState<number>(0);

  const dispatch = useAppDispatch();
  const containerState = useAppSelector(selectContainerState);

  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);

  const exportListeMeldungenState = useAppSelector(selectExportListeState);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.auftraege.length > 0) {
      setAnchorEl(event.currentTarget.parentNode.parentNode);
    }
  };

  useEffect(() => {
    if (containerState.exportliste.length <= 0) {
      setPage(0);
      handleClose();
    }
  }, [containerState.exportliste.length]);

  useEffect(() => {
    if (containerState.exportliste.length > 0) {
      const pageSize = 50;
      let meldungenToLoad: string[] =
        containerState.exportliste.length < pageSize
          ? containerState.exportliste
          : containerState.exportliste.length -
              exportListeMeldungenState.items.length >
            pageSize
          ? containerState.exportliste.slice(
              page * pageSize,
              (page + 1) * pageSize
            )
          : containerState.exportliste.slice(
              page * pageSize,
              containerState.exportliste.length
            );
      const nextPage: boolean = page > 0;
      dispatch(getExportListeMeldungen({ ids: meldungenToLoad, nextPage }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <ListenHeader
          meldungsCount={containerState.exportliste.length}
          onCancel={props.onCancel}
          onExport={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e)}
        />
        <AuftraegePopover
          open={open}
          anchorEl={anchorEl}
          auftraege={props.auftraege}
          exportLength={containerState.exportliste.length}
          handleClose={handleClose}
          onExport={props.onExport}
          letzterAuftrag={props.letzterAuftrag}
        />
      </div>
      <div>
        <AuftragsToolbar
          onRemove={() => {
            dispatch(exportListeLeeren({})).then(() =>
              dispatch(getExportListe())
            );
          }}
        />
      </div>
      <div className={classes.items}>
        {exportListeMeldungenState.items.length > 0 &&
          exportListeMeldungenState.items.map((m) => (
            <div key={m.id}>
              <ListenItem
                meldung={m}
                onRemove={() => {
                  dispatch(deleteMeldung(m.meldungDetailUrl)).then(() =>
                    dispatch(getExportListe())
                  );
                }}
              />
            </div>
          ))}
        <div className={classes.mehrButtonContainer}>
          {exportListeMeldungenState.status === "loading" ? (
            <CircularProgress color="primary" />
          ) : (
            containerState.exportliste.length > 50 &&
            containerState.exportliste.length >
              exportListeMeldungenState.items.length && (
              <Button
                color="primary"
                onClick={() => setPage((page) => page + 1)}
              >
                <Trans id="button.export.mehrladen">Mehr laden</Trans>
              </Button>
            )
          )}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    width: 600,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  items: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    overflow: "auto",
  },
  header: {
    padding: 10,
    position: "sticky",
    top: 0,
  },
  mehrButtonContainer: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
  },
}));
