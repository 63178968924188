export const GET_MEDIENARTEN = `query get{
  medienart {
    bezeichner
    gattungBezeichner
    gattung
    name
    standardInSuche
  }
}`;

export const SEARCH_QUERY_SEARCHID = `query get($searchId: String) {
  resolveSearchQuery(searchId: $searchId) {
    count
    felder {
      bedingung
      feld
      modifier
      query
    }
    herkunftsBezeichner
    medienartBezeichner
    zeitraum {
      bis
      vordefinierterZeitpunkt
      von
    }
    spracheBezeichner
  }
}
`;

export const GET_CONTAINER = `query get($localeTag:String) {
  container  {
    accountName
    toggleMeldungenMaximaleAnzahl
    toggleAllerMeldungenErlaubt
    letzterExportierterAuftrag
    bedingungen {
      name
      bezeichner
    }
    suchQueryFelder {
      name
      bezeichner
    }
    modifiers {
      name
      bezeichner
    }
    zeitangaben {
      bezeichner
      name
      zeitraum {
        von
        bis
      }
    }
    medienarten (localeTag: $localeTag){
      bezeichner
      gattungBezeichner
      gattung
      name
    }
    kontinente {
      bezeichner
      name
    }
    herkunftslaender {
      bezeichner
      name
      kontinentName {
        bezeichner
        name
      }
    }
    sprachen {
      bezeichner
      name
    }
    auftraege {
      id
      bezeichnung
      auftragsNummer
    }
    exportliste 
  }
}`;

export const GET_AUTHORIZATION = `query get {
  authorization {
    istAuthentifiziert
    sessionId
  }
}`;

export const GET_ZEITANGABEN = `query get{
  zeitangabe {
    bezeichner
    name
    zeitraum {
      von
      bis
    }
  }
}`;

export const GET_MELDUNGEN_BY_IDS = `query get($ids: [String!], $localeTag:String){
  meldungen(ids: $ids, localeTag: $localeTag){
    id
    veroeffentlicht 
    previewHeadlineHtml
    meldungDetailUrl
    herkunftsland{
      name
    }
    quelle{
      quelleTitel
    }
  }
}`;


export const GET_MELDUNGSDETAIL = `query get($id: String!, $query: String!, $localeTag:String){
  meldung(id: $id, localeTag: $localeTag){
    id
    direktUrl
    
    empfangen
    veroeffentlicht
    meldungDetailUrl

    previewTextHtml
    previewHeadlineHtml

    headlineHtml(query: $query)
    meldungHtml(query: $query)

    autor {
      autorName
      autorAnzeigename
      autorinformation
    }

    medium{
      name
      webseite
      webseiteinformation
      auflageinformation
      id
      ivwNummer
      sprache {
        name
      }
      herkunftsland {
        bezeichner
        name
      }
      medienArtBezeichner
      medienart {
        name
        nameMitGattung
      }
      erscheinungsweise {
        name
      }

      wemfgebiet {
        information
      }

      onlineVisits
      reichweite
      onlinePageImpressions

      printGedruckteAuflage
      printVerbreiteteAuflage
      printVerkaufteAuflage

      verlaginformationen
      verlag {
        name
      }

      redaktioninformation
      redaktion {
        chefredakteur
        email
        fax
        telefon
      }

      regionalitaetinformation
    }

    medienart {
      nameMitGattung
      name
    }

    mediengattung {
      bezeichner
      name
    }

    reichweite
    anzeigenAequivalenzWert
    
    herkunftsland {
      name
    }

    sprache {
      name
      nameEnglish
    }

    tags
    kategorien

    mediaAttachments {
      description
      mediaLink
      mediaUrl
    }
    
    engagements{
      anzahlComments
      anzahlDislikes
      anzahlLikes
      anzahlShares
      anzahlVideoViews
    }

    printInformationen {
      farbigkeitinformation
      groesseinformation
      platzierunginformation
      seiteName
    }
    quelle {
      quelleLink
      quelleTitel
      quelleInformation
    }
    exportInformation {
      exportDatum
      exportStatus
    }
  }
}`;

export const SEARCH_MELDUNGEN = `query get($search: SearchInput,$localeTag:String){
  searchScrollResult(search: $search){
    anzahlGesamtFormattiert (localeTag: $localeTag)
    anzahlGesamt
    nextLink
    meldungen{
      id
      meldungDetailUrl
      empfangen(localeTag: $localeTag)
      veroeffentlicht(localeTag:$localeTag)
      representantBildUrl
      direktUrl
      medienartBezeichner
      medienart (localeTag:$localeTag) {
        name
        nameMitGattung
        bezeichner
        gattungBezeichner
      }
      sprache (localeTag:$localeTag) {
        bezeichner
        name
      }

      herkunftsland (localeTag:$localeTag) {
        bezeichner
        name
      }

      previewTextHtml
      previewHeadlineHtml


      tags
      kategorien
      quelle {
        quelleLink,
        quelleTitel
      }
      exportInformation {
        exportDatum
        exportStatus
      }
    }
  }
}`;

export const GET_AUFTRAEGE = `query get{
  auftraege {
    id
    bezeichnung
    auftragsNummer
  }
}`;

export const SEARCH_MELDUNGEN_NEXT = `query get($nextLink: String,$localeTag:String){
  searchScrollResultNext(nextLink: $nextLink){
    anzahlGesamtFormattiert (localeTag: $localeTag)
    anzahlGesamt
    nextLink
    meldungen{
      id
      meldungDetailUrl
      empfangen(localeTag: $localeTag)
      veroeffentlicht(localeTag:$localeTag)
      representantBildUrl
      direktUrl
      medienartBezeichner
      medienart (localeTag:$localeTag) {
        name
        nameMitGattung
        bezeichner
        gattungBezeichner
      }
      sprache (localeTag:$localeTag) {
        bezeichner
        name
      }

      herkunftsland (localeTag:$localeTag) {
        bezeichner
        name
      }

      previewTextHtml
      previewHeadlineHtml


      tags
      kategorien
      quelle {
        quelleLink,
        quelleTitel
      }
      exportInformation {
        exportDatum
        exportStatus
      }
    }
  }
}`;
